import React, { Component } from "react";
import WebSocket from "./WebSocket";
import LyricsSlide from "./LyricsSlide";
import SubtitlesSlide from "./SubtitlesSlide";
import Config from "../utils/config";
import BibleBanner from "../resources/images/biblebanner.jpg";
import Mime from "mime";

export default class Screen extends Component {
  state = {
    hasProxy: false,
    data:
      this.props.type !== "subtitles" && this.props.type !== "screen-novideo"
        ? {
            type: "video",
            controls: true,
            url: process.env.PUBLIC_URL + "/adventistlogo.m4v",
            screens: [],
          }
        : {
            content: "",
            name: "",
            reference: "",
            translation: "kjv",
            type: "bible",
            screens: [],
          },
    subtitle: "",
    overlay: "",
    remainingTime: "",
    videoStartTime: undefined,
    videoEndTime: undefined,
    started: true,
    isConnected: undefined,
    src: undefined,
  };

  getAbsoluteUrl = (url) => {
    let absolute = url;
    if (absolute.startsWith("./")) {
      let pos = window.location.href.lastIndexOf("/");
      if (pos > "https://".length) {
        absolute =
          window.location.href.substring(0, pos) + absolute.substring(1);
      } else {
        absolute = window.location.href + absolute.substring(1);
      }
    } else if (absolute.startsWith("/")) {
      absolute = Config.getHost() + absolute;
    }

    return absolute;
  };

  getSrc = async (data) => {
    let url = data.url || Config.getMediaDownloadUrl() + encodeURI(data.path);
    let absoluteUrl = this.getAbsoluteUrl(url);
    let src = absoluteUrl;
    if (src.startsWith(Config.getMediaDownloadUrl())) {
      src = src.substring(Config.getMediaDownloadUrl().length);
      src = decodeURIComponent(src);
    }
    return absoluteUrl + "?downloadHostURL=" + Config.getHost();
  };

  screenRef = undefined;
  instance = undefined;

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
    if (this.props.type === "preview") {
      WebSocket.offLocal("preview", this.listenerPreviewPlay);
    } else if (this.props.type === "subtitles") {
      WebSocket.off("subtitles", this.listenerSubtitles);
    } else {
      WebSocket.off("play", this.listenerPreviewPlay);
    }
    WebSocket.off("stop", this.listenerStop);
    WebSocket.off("seek", this.listenerSeek);
    WebSocket.off("timeupdate", this.listenerTimeupdate);
    WebSocket.off("overlay", this.listenerOverlay);
  }

  listenerPreviewPlay = async (data) => {
    data = JSON.parse(data);
    if (
      this.props.type === "preview" &&
      data.__deviceid !== Config.getDeviceId()
    ) {
      return;
    }
    let videoStartTime = this.parseTimestamp(data.starttime, undefined);
    let videoEndTime = this.parseTimestamp(data.endtime, undefined);
    let state = {
      data,
      videoStartTime,
      videoEndTime,
      started: false,
      src: await this.getSrc(data),
      loop: data.loop,
    };

    let contentType = Mime.getType(this.state.data.path);
    if (
      data.type === "media" &&
      contentType &&
      contentType.startsWith("image/")
    ) {
      let img1 = new Image();
      img1.src = state.src;
      img1.onload = () => {
        this.setState(state);
      };
      img1.onerror = (err) => {
        this.setState(state);
      };
    } else {
      this.setState(state);
    }
  };

  listenerSubtitles = (data) => {
    data = JSON.parse(data);
    if (data.screens && data.screens.includes(this.props.type)) {
      if (data.type === "subtitle") {
        this.setState({
          subtitle: data.name,
        });
      } else if (data.type === "bible") {
        this.setState({ data });
      }
    }
  };

  listenerStop = () => {
    this.setState({
      data: {
        type: "blank",
      },
    });
  };

  listenerSeek = (msg) => {
    msg = JSON.parse(msg);
    if (this.videoElement) {
      this.videoElement.currentTime = msg.pos;
    }
  };

  listenerOverlay = (data) => {
    data = JSON.parse(data);
    this.setState({
      overlay: data.url,
    });
  };

  listenerTimeUpdate = (msg) => {
    msg = JSON.parse(msg);
    if (this.state.started === true && this.videoElement) {
      this.videoElement.currentTime = msg.pos;
      this.setState({
        started: false,
        data: msg.data,
        videoStartTime: msg.pos,
      });
    }
    if (this.props.type === "novideo") {
      const remainingTime = this.formatTimestampMMSS(
        Math.floor(
          (this.state.videoEndTime ? this.state.videoEndTime : msg.duration) -
            msg.pos
        )
      );
      this.setState({ remainingTime });
    }
  };

  async componentDidMount() {
    document.title = "Site Worship - " + this.props.type;
    if (this.instance) {
      return;
    }
    this.instance = this;
    this.screenRef = React.createRef();
    this.interval = setInterval(() => {
      if (WebSocket.isConnected()) {
        this.setState({ isConnected: true });
      } else if (this.state.isConnected !== false) {
        this.setState({ isConnected: false });
      }
    }, 1000);

    if (this.props.type === "preview") {
      WebSocket.onLocal("preview", this.listenerPreviewPlay);
    } else {
      WebSocket.on("subtitles", this.listenerSubtitles);
      WebSocket.on("play", this.listenerPreviewPlay);
    }

    WebSocket.on("stop", this.listenerStop);

    WebSocket.on("seek", this.listenerSeek);

    WebSocket.on("timeupdate", this.listenerTimeUpdate);
    WebSocket.on("overlay", this.listenerOverlay);
  }

  renderImage = () => {
    if (this.state.data.mediaNotes && this.props.type === "notes") {
      return (
        <div
          id="image"
          style={{
            color: "#FFFFFF",
            fontSize: localStorage.getItem("NotesFontSize") || "8vh",
            padding: localStorage.getItem("NotesPadding") || "0",
          }}
        >
          <div>
            {this.state.data.mediaNotes.split("\n").map((line) => (
              <div
                style={{
                  textAlign: "left",
                  backgroundColor: "#000000",
                  width: "100%",
                }}
              >
                {" "}
                {line}
                <br />
              </div>
            ))}
          </div>
          <div style={{ display: "flex", flex: "1 1 auto" }}>
            <img
              alt=""
              src={this.state.src}
              style={{ width: "100%", height: "100%" }}
            ></img>
          </div>
        </div>
      );
    }
    return (
      <div
        id="image"
        style={{
          backgroundImage: "url('" + this.state.src + "')",
        }}
      ></div>
    );
  };

  renderBible = () => {
    let fontSize = this.props.type === "subtitles" ? "3vw" : "6vw";
    if (!this.state.data.screens) {
      return null;
    }
    let currentScreen = this.state.data.screens.filter(
      (screen) => screen.id === this.props.type
    );
    if (currentScreen.length === 1) {
      currentScreen = currentScreen[0];
    } else {
      return null;
    }
    if (!currentScreen.enabled) {
      return null;
    }
    if (!this.state.data.content) {
      return null;
    }
    fontSize =
      this.props.type === "subtitles"
        ? String(currentScreen.size / 10) + "px"
        : String(currentScreen.size / 5) + "px";
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flex: "1 1 auto",
          color: "#FFFFFF",
          backgroundColor:
            this.props.type === "subtitles" ? "#00FF00" : "#000000",
          fontSize: fontSize,
        }}
      >
        <div style={{ flex: "1 1 auto" }}>&nbsp;</div>
        <div
          style={{
            flex: "0 0 auto",
            padding: "1vw 3vw 3vw 15vw",
            backgroundColor: this.state.data.content && "#000000",
            backgroundImage: this.state.data.content && `url(${BibleBanner})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "bottom",
            backgroundSize: "100%",
            fontSize: fontSize,
          }}
        >
          {this.state.data.content}
          <br />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ flex: "1 1 auto" }}>&nbsp;</div>
            {this.state.data.reference && (
              <div>
                {this.state.data.reference} (
                {this.state.data.translation &&
                  this.state.data.translation.toUpperCase()}
                )
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderBlack = (message) => {
    if (message) {
      return (
        <div
          style={{
            color: "white",
            position: "absolute",
            // top: "50%",
            left: "50%",
            // transform: "translateX(-50%) translateY(-50%)",
            transform: "translateX(-50%)",
          }}
        >
          <span style={{ fontSize: "20px" }}> {message}</span>
        </div>
      );
    } else {
      return null;
    }
  };

  formatTimestampMMSS(value) {
    let seconds = Math.floor(value);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = minutes % 60;
    return (
      (hours > 0 ? (hours < 10 ? "0" : "") + parseInt(hours) + ":" : "") +
      (minutes < 10 ? "0" : "") +
      parseInt(minutes) +
      ":" +
      (seconds < 10 ? "0" : "") +
      seconds
    );
  }

  parseTimestamp(strValue, defaultValue) {
    if (!strValue) {
      return defaultValue;
    }
    let parts = strValue.split(":");
    if (parts.length === 2) {
      return isNaN(parts[0]) || isNaN(parts[1])
        ? defaultValue
        : parseInt(parts[0]) * 60 + parseInt(parts[1]);
    } else if (parts.length === 3) {
      return isNaN(parts[0]) || isNaN(parts[1]) || isNaN(parts[2])
        ? defaultValue
        : parseInt(
            parts[0] * 60 * 60 + parseInt(parts[1]) * 60 + parseInt(parts[2])
          );
    } else {
      return defaultValue;
    }
  }

  lastTimePos = 0;
  videoElement = undefined;

  renderVideo = () => {
    let fontSize = "16px";
    let { url, controls, loop, muted, path, name } = this.state.data;
    if (this.props.type === "subtitles") {
      return null;
    } else if (this.props.type === "novideo") {
      return (
        <div
          style={{
            margin: "4px, 4px",
            padding: "4px",
            fontSize: fontSize,
            backgroundColor: "#000000",
            width: "100%",
            height: "100%",
            textAlign: "justify",
          }}
        >
          {" "}
          {this.renderBlack(
            <span style={{ textAlign: "top" }}>
              Video
              <br />"{name && name}"<br />
              will not be shown
              <br />
              on a mobile device
              <br />
              Remaining: {this.state.remainingTime}
            </span>
          )}{" "}
        </div>
      );
    }

    if (path && !url) {
      url = Config.getMediaDownloadUrl() + path;
    }
    let urlLower = url.toLowerCase();
    let type = "video/unknown";
    if (urlLower.endsWith(".mp4")) {
      type = "video/mp4";
    } else if (urlLower.endsWith(".m4v")) {
      type = "video/mp4";
    } else if (urlLower.endsWith(".mov")) {
      type = "video/mp4";
    } else if (urlLower.endsWith(".webm")) {
      type = "video/webm";
    }
    url = url + "?downloadHostURL=" + Config.getHost();

    controls =
      controls ||
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    return (
      <div
        style={{
          flex: "1 1 80%",
          height: "100%",
        }}
      >
        <video
          ref={(videoElement) => {
            this.videoElement = videoElement;
          }}
          key={this.state.data.url || this.state.data.path}
          autoPlay={
            this.props.type !== "preview" && this.props.type !== "notes"
          }
          controls={this.props.type === "preview" || controls}
          loop={
            this.props.type === "preview" || this.props.type === "notes"
              ? false
              : loop
          }
          muted={muted}
          id="video"
          onTimeUpdate={(event) => {
            let timePos = Math.floor(event.target.currentTime);
            if (
              !loop &&
              this.state.videoEndTime &&
              timePos >= this.state.videoEndTime
            ) {
              this.videoElement.pause();
              WebSocket.emit("ended", { src: this.state.src });
              this.setState({
                data: {
                  type: "blank",
                },
              });
            }
            if (timePos !== this.lastTimePos) {
              this.lastTimePos = timePos;
              this.setState({
                remainingTime: this.formatTimestampMMSS(
                  Math.floor(
                    (this.state.videoEndTime
                      ? this.state.videoEndTime
                      : event.target.duration) - event.target.currentTime
                  )
                ),
              });
              WebSocket.emit("timeupdate", {
                pos: timePos,
                duration: Math.floor(event.target.duration),
                data: this.state.data,
              });
            }
          }}
          onLoadedMetadata={() => {
            if (this.state.videoStartTime) {
              this.videoElement.currentTime = this.state.videoStartTime;
            }
          }}
          onEnded={(event) => {
            WebSocket.emit("ended", { src: this.state.src });
            this.setState({
              data: {
                type: "blank",
              },
            });
          }}
        >
          <source id="videosource" key={url} src={this.state.src} type={type} />
        </video>
      </div>
    );
  };

  renderAudio = () => {
    let { url, controls, loop, muted, path, name } = this.state.data;
    if (this.props.type === "subtitles") {
      return null;
    } else if (this.props.type === "novideo") {
      return (
        <div
          style={{
            margin: "4px, 4px",
            padding: "4px",
            fontSize: "medium",
            backgroundColor: "#000000",
            width: "100%",
            height: "100%",
            textAlign: "justify",
          }}
        >
          {" "}
          {this.renderBlack(
            <span style={{ textAlign: "top" }}>
              Audio
              <br />"{name && name}"<br />
              will not be played
              <br />
              on a mobile device
              <br />
              Remaining: {this.state.remainingTime}
            </span>
          )}{" "}
        </div>
      );
    }

    if (path && !url) {
      url = Config.getMediaDownloadUrl() + path;
    }
    let urlLower = url.toLowerCase();
    let type = "audio/unknown";
    if (urlLower.endsWith(".mp3")) {
      type = "audio/mp3";
    } else if (urlLower.endsWith(".aac")) {
      type = "audio/aac";
    } else if (urlLower.endsWith(".m4a")) {
      type = "audio/x-m4a";
    }
    url = url + "?downloadHostURL=" + Config.getHost();

    controls =
      controls ||
      /iPad|iPhone|iPod/.test(navigator.platform) ||
      (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);
    return (
      <div
        style={{
          flex: "1 1 80%",
          height: "100%",
        }}
      >
        <audio
          ref={(videoElement) => {
            this.videoElement = videoElement;
          }}
          key={this.state.data.url || this.state.data.path}
          autoPlay={
            this.props.type !== "preview" && this.props.type !== "notes"
          }
          controls={
            this.props.type === "preview" ||
            this.props.type === "notes" ||
            controls
          }
          loop={
            this.props.type === "preview" || this.props.type === "notes"
              ? false
              : loop
          }
          muted={muted}
          id="video"
          onTimeUpdate={(event) => {
            let timePos = Math.floor(event.target.currentTime);
            if (this.state.videoEndTime && timePos >= this.state.videoEndTime) {
              this.videoElement.pause();
              WebSocket.emit("ended", { src: this.state.src });
              this.setState({
                data: {
                  type: "blank",
                },
              });
            }
            if (timePos !== this.lastTimePos) {
              this.lastTimePos = timePos;
              this.setState({
                remainingTime: this.formatTimestampMMSS(
                  Math.floor(
                    (this.state.videoEndTime
                      ? this.state.videoEndTime
                      : event.target.duration) - event.target.currentTime
                  )
                ),
              });
              WebSocket.emit("timeupdate", {
                pos: timePos,
                duration: Math.floor(event.target.duration),
                data: this.state.data,
              });
            }
          }}
          onLoadedMetadata={() => {
            if (this.state.videoStartTime) {
              this.videoElement.currentTime = this.state.videoStartTime;
            }
          }}
          onEnded={(event) => {
            WebSocket.emit("ended", { src: this.state.src });
            this.setState({
              data: {
                type: "blank",
              },
            });
          }}
        >
          <source id="audiosource" key={url} src={this.state.src} type={type} />
        </audio>
      </div>
    );
  };

  renderIframe = () => {
    const YOUTUBE_BASEURL = "https://www.youtube.com/watch?v=";
    let url = this.state.data.url;
    let iframeProps = {
      id: "iframe",
      src: url,
      frameBorder: "0",
      marginHeight: "0",
      marginWidth: "0",
      style: { flex: "1 1 100%" },
      scrolling: "auto",
    };
    if (url.startsWith(YOUTUBE_BASEURL)) {
      url =
        "https://www.youtube.com/embed/" +
        url.substring(YOUTUBE_BASEURL.length);
      iframeProps = {
        ...iframeProps,
        src: url,
        autoPlay: false,
        allow:
          "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
        allowFullScreen: "true",
      };
    }
    return <iframe title="IFrame - Screen" {...iframeProps}></iframe>;
  };

  renderFullScreenButton() {
    return (
      <button
        style={{ flex: "1 1 auto" }}
        onClick={() => {
          try {
            this.screenRef.current.requestFullscreen({ navigationUI: "hide" });
          } catch (ex) {
            console.log("EX", ex);
          }
        }}
      >
        Enter Full Screen
      </button>
    );
  }

  render() {
    let body = null;
    let type = this.state.data.type;
    if (type === "media") {
      let contentType = Mime.getType(this.state.data.path);
      if (contentType && contentType.startsWith("video/")) {
        type = "video";
      } else if (contentType && contentType.startsWith("audio/")) {
        type = "audio";
      } else if (contentType && contentType.startsWith("image/")) {
        type = "image";
      }
    }
    if (type === "video") {
      if (!this.state.loop && this.props.type === "stage") {
        body = (
          <React.Fragment>
            {" "}
            {this.renderVideo()}
            <div
              style={{
                position: "absolute",
                left: "0px",
                bottom: "0px",
                margin: "10px",
                padding: "10px 10px",
                fontSize: "60px",
                fontFamily: "Helvetica",
                color: "#FFF",
                backgroundColor: "#000",
                opacity: 0.5,
                zIndex: 999,
              }}
            >
              {" "}
              {this.state.remainingTime}{" "}
            </div>
            {this.state.overlay && (
              <iframe
                title="Overlay"
                src={this.state.overlay}
                allowtransparency="true"
                style={{
                  height: "100vh",
                  width: "100vw",
                  position: "absolute",
                  top: "0px",
                  left: "0px",
                  zIndex: 998,
                }}
                scrolling="no"
                frameborder="0px"
              ></iframe>
            )}
          </React.Fragment>
        );
      } else {
        body = this.renderVideo();
      }
    } else if (type === "audio") {
      body = this.renderAudio();
    } else if (type === "image" && this.props.type !== "subtitles") {
      body = this.renderImage();
    } else if (type === "iframe" && this.props.type !== "subtitles") {
      body = this.renderIframe();
    } else if (type === "lyrics" && this.props.type !== "subtitles") {
      body = <LyricsSlide slide={this.state.data.lyric} />;
    } else {
      if (this.props.type === "novideo") {
        body = this.renderBlack(
          "Currently: " + this.state.data.name
            ? this.state.data.name
            : "no content"
        );
      } else {
        body = this.renderBlack();
      }
    }
    return (
      <React.Fragment>
        <div
          ref={this.screenRef}
          className="flex-column d-print-none"
          style={{
            margin: "0px",
            backgroundColor:
              this.props.type === "subtitles" ? "#00FF00" : "#000000",
            overflow: "hidden",
            justifyContent: "center",
          }}
        >
          {" "}
          {this.props.type === "preview" || this.props.type === "subtitles" ? (
            body
          ) : Config.getScreen().fullscreen ? (
            <div className="flex-column" style={{ justifyContent: "center" }}>
              {" "}
              {body}
              {document.fullscreenElement === null &&
                this.renderFullScreenButton()}{" "}
            </div>
          ) : (
            body
          )}{" "}
        </div>
        {this.state.subtitle && (
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
            }}
          >
            <SubtitlesSlide
              name={this.state.subtitle}
              green={this.props.type === "subtitles"}
            />
          </div>
        )}
        {this.state.data.type === "bible" && this.renderBible()}
        {this.state.isConnected === false && this.props.type !== "preview" && (
          <div
            style={{
              backgroundColor: "#FF0000",
              color: "#FFFFFF",
              width: "100%",
              textAlign: "center",
            }}
          >
            CONNECTION LOST
          </div>
        )}
        {this.props.type === "preview" &&
          this.props.doPrev &&
          this.props.doNext && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <button
                onClick={() => {
                  this.props.doPrev();
                }}
                style={{
                  flex: "1 1 auto",
                  width: "50%",
                  height: "80px",
                }}
              >
                Previous
              </button>
              <button
                style={{
                  flex: "1 1 auto",
                  width: "50%",
                  height: "80px",
                }}
                onClick={() => {
                  this.props.doNext();
                }}
              >
                Next
              </button>
            </div>
          )}{" "}
      </React.Fragment>
    );
  }
}
