import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import Config from "../utils/config";

import { Component } from "react";

export default class WebSocket extends Component {
  static s_socket = undefined;
  static s_sessionid = uuidv4();

  static getSocket() {
    if (WebSocket.s_socket === undefined) {
      let websocket = Config.getWebsocket();
      let path = "/socket.io";
      let posSlash = websocket.indexOf("/", "https://".length);
      if (posSlash !== -1) {
        path = websocket.substring(posSlash) + path;
        websocket = websocket.substring(0, posSlash);
      }
      WebSocket.s_socket = io.connect(websocket, { path: path });
      WebSocket.sendDeviceInfo();
    }
    return WebSocket.s_socket;
  }

  static emitLocal(key, message, list) {
    if (list) {
      message = { ...message, __list: list, __deviceid: Config.getDeviceId() };
    }
    var evt = new CustomEvent(key, {
      detail: JSON.stringify(message, null, 4),
    });
    window.dispatchEvent(evt);
  }

  static onLocal(key, func) {
    window.addEventListener(
      key,
      (data) => {
        func(data.detail);
      },
      false
    );
  }

  static offLocal(key, func) {
    //TODO window.removeEventListener();
  }

  static emit(key, message, list) {
    if (list) {
      message = {
        ...message,
        __list: list,
        __deviceid: Config.getDeviceId(),
      };
    }
    WebSocket.getSocket().emit(key, JSON.stringify(message, 4));
  }

  static on(key, func) {
    WebSocket.getSocket().on(key, func);
  }

  static off(key, func) {
    WebSocket.getSocket().off(key, func);
  }

  static isConnected() {
    return this.getSocket().connected;
  }

  static sendDeviceInfo() {
    let device_id = Config.get("device_id");
    let device_secret = Config.get("device_secret");
    let playpermissions = Config.getPlaypermissions();
    if (playpermissions) {
      playpermissions = playpermissions.split(" ");
    }
    if (!device_id || !device_secret) {
      Config.set("device_id", device_id || uuidv4());
      Config.set("device_secret", device_secret || uuidv4());
    }

    WebSocket.emit("device", {
      type: "control",
      id: device_id,
      secret: device_secret,
      playpermissions,
      sessionid: WebSocket.s_sessionid,
    });
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      WebSocket.sendDeviceInfo();
    }, 5000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  render() {
    return null;
  }
}
