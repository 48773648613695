import React, { useState } from "react";
import "../resources/fonts/vera/fonts.css";

export default function SongSheets(props) {
  const [copyToClipboard, setCopyToClipboard] = useState("Copy to Clipboard");

  const factor = (1.0 * 12.0) / 16.0;
  const titleSize = String(factor * 2) + "em";
  const subTitleSize = String(factor * 1) + "em";
  const splitSize = String(factor * 0.6) + "em";
  const bodySize = String(factor * 1) + "em";
  const copyRightSize = String(factor * 0.4) + "em";

  function lyricToHtml(lyric) {
    if (!lyric) {
      return null;
    }

    let body = [];
    if (lyric.Title && lyric.Title.text && lyric.Title.text[0]) {
      body.push(
        <div
          key={body.length}
          style={{
            fontFamily: "Vera",
            fontSize: titleSize,
            marginTop: "0.5em",
          }}
        >
          {lyric.Title.text[0]}
        </div>
      );
    }
    if (lyric.Subtitle && lyric.Subtitle.text && lyric.Subtitle.text[0]) {
      body.push(
        <div
          key={body.length}
          style={{
            fontFamily: "Vera",
            fontSize: subTitleSize,
            fontWeight: "bold",
          }}
        >
          {lyric.Subtitle.text[0]}
        </div>
      );
    }
    if (lyric.Body && lyric.Body.text) {
      body.push(
        <div key={body.length} className="print-no-break">
          {!lyric.isSplit && (
            <div style={{ fontFamily: "Vera", fontSize: splitSize }}>
              &nbsp;
            </div>
          )}
          {lyric.type !== "Verse" && !lyric.isSplit && (
            <div style={{ fontFamily: "Vera", fontWeight: "bold" }}>
              {lyric.type}
            </div>
          )}
          {lyric.Body.text.map((line, index) => (
            <div key={index} style={{ fontFamily: "Vera", fontSize: bodySize }}>
              {line}
            </div>
          ))}
        </div>
      );
    }
    if (lyric.Copyright && lyric.Copyright.text && lyric.Copyright.text[0]) {
      body.push(
        <div
          key={body.length}
          style={{ fontFamily: "Vera", fontSize: copyRightSize }}
        >
          <br />
          {lyric.Copyright.text.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      );
    }
    return body;
  }

  let songSheetTitle = props.playlistName;
  if (
    songSheetTitle &&
    songSheetTitle.length >= 8 &&
    !isNaN(songSheetTitle.substring(0, 8)) &&
    Number(songSheetTitle) >= 20190000
  ) {
    songSheetTitle =
      songSheetTitle.substring(4, 6) +
      "/" +
      songSheetTitle.substring(6, 8) +
      "/" +
      songSheetTitle.substring(0, 4) +
      songSheetTitle.substring(8);
  }

  let chorus = 0;
  let ret = [];
  for (let i = 0; i < props.items.length; i++) {
    const item = props.items[i];
    if (item.type !== "lyrics") {
      continue;
    }
    let lyric = item.lyric;
    if (lyric && lyric.Title && lyric.Title.text && lyric.Title.text[0]) {
      chorus = 0;
    }
    if (lyric.type === "Chorus") {
      if (!lyric.isSplit) {
        chorus++;
      }
      if (chorus > 1) {
        lyric = { ...lyric };
        lyric.Body = { ...lyric.Body };
        lyric.Body.text = [];
      }
    }
    if (lyric.isSplit) {
      lyric.Subtitle = { ...lyric.Subtitle };
      lyric.Subtitle.text = [];
    }
    if (lyric.type !== "Chorus" || chorus <= 1 || !lyric.isSplit) {
      ret.push(
        <React.Fragment key={ret.length}>{lyricToHtml(lyric)}</React.Fragment>
      );
    } else if (
      lyric.type === "Chorus" &&
      lyric.Copyright &&
      lyric.Copyright.text &&
      lyric.Copyright.text.length > 0
    ) {
      lyric.Title = { ...lyric.Title };
      lyric.Title.text = [];
      ret.push(
        <React.Fragment key={ret.length}>{lyricToHtml(lyric)}</React.Fragment>
      );
    }
  }
  return (
    <React.Fragment>
      <div className="d-print-none">&nbsp;</div>
      <div className="flex-row-fixed d-print-none">
        <button
          className="btn btn-secondary"
          onClick={() => {
            let range = document.createRange();
            range.selectNode(document.getElementById("songsheet_content"));
            window.getSelection().removeAllRanges(); // clear current selection
            window.getSelection().addRange(range); // to select text
            document.execCommand("copy");
            window.getSelection().removeAllRanges(); // to deselect
            setCopyToClipboard("Copied!");
            setTimeout(() => {
              setCopyToClipboard("Copy to Clipboard");
            }, 2000);
          }}
        >
          {copyToClipboard}
        </button>
        &nbsp;
        <button
          className="btn btn-secondary"
          onClick={() => {
            window.print();
          }}
        >
          Print (two columns (Chrome only))
        </button>
      </div>
      <div>&nbsp;</div>
      <div id="songsheet_content">
        <div
          style={{
            border: "0px solid",
            fontSize: "2em",
            textDecoration: "underline",
            textAlign: "center",
          }}
        >
          Song Sheet {songSheetTitle}
        </div>
        <div>&nbsp;</div>
        <div className="songsheet-print">{ret}</div>
      </div>
    </React.Fragment>
  );
}
