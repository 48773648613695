const { isElectron } = require("./electron");

export default class Config {
  static get(key) {
    let siteworship = localStorage.getItem("siteworship");
    if (siteworship) {
      siteworship = JSON.parse(siteworship);
      return siteworship[key];
    } else {
      return undefined;
    }
  }

  static set(key, value) {
    let siteworship = localStorage.getItem("siteworship");
    if (siteworship) {
      siteworship = JSON.parse(siteworship);
    } else {
      siteworship = {};
    }
    siteworship[key] = value;
    localStorage.setItem("siteworship", JSON.stringify(siteworship));
  }

  static getName() {
    return Config.get("name");
  }

  static setName(name) {
    Config.set("name", name);
  }

  static getPlaypermissions() {
    return Config.get("playpermissions");
  }

  static setPlaypermissions(name) {
    Config.set("playpermissions", name);
  }

  static getScreen() {
    let screen = Config.get("screen");
    if (!screen) {
      screen = {
        x: 500,
        y: 200,
        width: 200,
        height: 200,
        fullscreen: false,
      };
    }
    return screen;
  }

  static setScreen(screen) {
    Config.set("screen", screen);
  }

  static getDeviceId() {
    return Config.get("device_id");
  }

  static setDeviceId(device_id) {
    Config.set("device_id", device_id);
  }

  static getDeviceSecret() {
    return Config.get("device_secret");
  }

  static setDeviceSecret(device_secret) {
    Config.set("device_secret", device_secret);
  }

  static getHost() {
    return (
      Config.get("host") ||
      window.location.protocol + "//" + window.location.host
    );
  }
  static setHost(host) {
    Config.set("host", host);
  }

  static getWebsocket() {
    return Config.get("websocket") || Config.getHost();
  }
  static setWebsocket(host) {
    Config.set("websocket", host);
  }

  static getMediaDownloadUrl() {
    //return Config.getHost() + "/api/media";

    return isElectron()
      ? "http://localhost:8199/api/media"
      : Config.getHost() + "/api/media";
  }

  static isElectron() {
    const userAgent = navigator.userAgent.toLowerCase();
    return userAgent.indexOf(" electron/") > -1;
  }

  static setAdditionalLyrics(ids) {
    Config.set("additional_lyrics", ids);
  }

  static getAdditionalLyrics() {
    return ["8d9521edc4ebd72f97451d078ed2a448ea73c250c58ae062de71c1984305ce15"]; //TODO
    /*    let ret = Config.get("additional_lyrics");
    if (ret) {
      return ret;
    } else {
      return [];
    }*/
  }

  static getBuild() {
    return "20241116_004339";
  }
}
